import axios, { InternalAxiosRequestConfig } from 'axios';
import getCookieValue from './getCookieValue';

export const axiosInstance = axios.create({
  baseURL: '/',
  headers: {
    accept: 'application/json, text/plain, */*',
    'access-domain': 'classifieds.content.management',
    'Content-Type': 'application/json',
    authorization: `Bearer ${getCookieValue('t') ?? ''}`,
  },
});

// before each request
axiosInstance.interceptors.request.use((config): InternalAxiosRequestConfig => {
  // Do something before request is sent
  const token = getCookieValue('t');

  if (token !== null && config.headers['authorization']) {
    config.headers['authorization'] = `Bearer ${token}`;
  }

  // always return the config otherwise the request will not be sent
  return config;
});
