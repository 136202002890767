import { OverviewPage } from '@features/OverviewPage';
import {
  useGetDictionariesQuery,
  useGetDistributionsQuery,
} from '@gql_codegen/price-management-config-types';
import { createFileRoute } from '@tanstack/react-router';

const OverviewPageRouteComponent = () => {
  return <OverviewPage />;
};

export const Route = createFileRoute('/$lang/price-management-config/')({
  component: OverviewPageRouteComponent,
  loader: ({ context: { queryClient } }) => {
    void Promise.all([
      queryClient.ensureQueryData({
        queryFn: useGetDistributionsQuery.fetcher(),
        queryKey: useGetDistributionsQuery.getKey(),
      }),
      queryClient.ensureQueryData({
        queryFn: useGetDictionariesQuery.fetcher(),
        queryKey: useGetDictionariesQuery.getKey(),
      }),
    ]);
  },
});
