import type { TableColumnType } from 'antd';
import { Table, Button } from 'antd';
import { css } from '@styled-system/css';
import { HStack } from '@styled-system/jsx';
import { Ad } from '@gql_codegen/price-management-config-types';
import type {
  TrackDistributionConfigWithOptionalData,
  ClusterType,
} from '../../store/trackDistributionStore';
import { EditOutlined } from '@ant-design/icons';
import { UseFormReset } from 'react-hook-form';

import { TrackDistributionSchemaType } from '../../model/trackDistributionSchema';
type ClusterCollapseProps = {
  country: string;
  cluster: ClusterType;
  showModal: () => void;
  reset: UseFormReset<TrackDistributionSchemaType>;
};
export const ClusterCollapse: React.FC<ClusterCollapseProps> = ({
  country,
  cluster,
  showModal,
  reset,
}: ClusterCollapseProps) => {
  const columns: TableColumnType<TrackDistributionConfigWithOptionalData>[] = [
    {
      title: 'Track',
      dataIndex: 'track',
      render: (value, _, idx) => (
        <span data-qa-selector={`track-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Experiment Name',
      dataIndex: 'experimentName',
      render: (value, _, idx) => (
        <span data-qa-selector={`experimentName-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Ratio',
      dataIndex: 'distributionRatio',
      render: (value, _, idx) => (
        <span data-qa-selector={`distributionRatio-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Sourcing Type',
      dataIndex: 'sourcingType',
      render: (value, _, idx) => (
        <span data-qa-selector={`sourcingType-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'createdAt',
      render: (value, _, idx) => (
        <span data-qa-selector={`createdAt-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      render: (value, _, idx) => (
        <span data-qa-selector={`createdBy-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Count of assigned Ads',
      dataIndex: 'countOfAssignedAds',
      render: (value, _, idx) => (
        <span data-qa-selector={`countOfAssignedAds-${idx}`}>{value}</span>
      ),
    },
    {
      title: 'Example of assigned Ads',
      dataIndex: 'examplesOfAssignedAds',
      render: (value: Ad[] | undefined, _, idx) => {
        return value
          ? value.map((item: Ad, i: number) => (
              <a
                key={i}
                data-qa-selector={`examplesOfAssignedAds-${idx}-${i}`}
                href={`/en/ad/${item.adId}`}
              >
                {item.label}
              </a>
            ))
          : null;
      },
    },
  ];

  const handleEditCluster = (country: string, cluster: ClusterType) => {
    reset({
      country,
      priceCluster: cluster.priceCluster,
      configs: cluster.configs
        .filter((cfg) => {
          // if cluster was already edited then we just return it since it does not have sourcingType field
          // otherwise we filter only internal records to avoid "external" duplications
          return cluster.isEdited ? true : cfg.sourcingType === 'internal';
        })
        .map((item) => ({
          track: item.track,
          experimentName: item.experimentName,
          distributionRatio: item.distributionRatio,
        })),
    });
    showModal();
  };

  return (
    <>
      <HStack className={css({ mb: '15px' })}>
        <Button
          type="primary"
          data-qa-selector="editClusterDataButton"
          onClick={() => {
            handleEditCluster(country, cluster);
          }}
        >
          <EditOutlined /> Edit Track Distribution
        </Button>
      </HStack>
      <Table
        data-qa-selector="clusterDistributionDataTable"
        size="small"
        columns={columns}
        pagination={false}
        tableLayout="fixed"
        dataSource={cluster.configs}
        rowKey={(record) => `${record.track}_${record.sourcingType ?? ''}`}
      ></Table>
    </>
  );
};
