import React, { StrictMode } from 'react';
import ReactDOMClient from 'react-dom/client';
import type { AppProps } from 'single-spa';
import singleSpaReact from 'single-spa-react';
import { RootComponent } from './root-component';
import './index.css';

const lifecycles = singleSpaReact<AppProps>({
  React,
  ReactDOMClient,
  rootComponent: RootComponent,
  errorBoundary: (err: Error) => (
    <div>
      <h2>Something went wrong.</h2>
      <pre>{JSON.stringify(err, null, 2)}</pre>
    </div>
  ),
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;

if (!('singleSpaNavigate' in window)) {
  const rootNode = document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root container not found');
  }

  const root = ReactDOMClient.createRoot(rootNode);
  root.render(
    <StrictMode>
      <RootComponent />
    </StrictMode>,
  );
}
