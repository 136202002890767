import { z } from 'zod';

export const TrackDistributionSchema = z.object({
  country: z.string().min(1, { message: 'Field is required' }),
  priceCluster: z.string().min(1, { message: 'Field is required' }),
  configs: z
    .array(
      z.object({
        experimentName: z.string().min(1, { message: 'Field is required' }),
        track: z.string().min(1, { message: 'Field is required' }),
        distributionRatio: z
          .number({ message: 'Field is required' })
          .int({ message: 'Only integers allowed' })
          .min(0)
          .max(100),
      }),
    )
    .superRefine((configs, ctx) => {
      const totalDistributionValue = configs.reduce(
        (acc, curr) => acc + curr.distributionRatio,
        0,
      );
      configs.map((config, idx) => {
        if (totalDistributionValue !== 100) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Ratio values must be 100% in total',
            path: [idx, 'distributionRatio'],
          });
        }
        const equalTracks = configs.filter((c) => c.track === config.track);
        if (equalTracks.length > 1) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Tracks must be unique',
            path: [idx, 'track'],
          });
        }
      });
    }),
});
export type TrackDistributionSchemaType = z.infer<
  typeof TrackDistributionSchema
>;

export const DefaultTrackDistributionFormValues = {
  country: '',
  priceCluster: '',
  configs: [{ track: '', experimentName: '', distributionRatio: 0 }],
} satisfies TrackDistributionSchemaType;
