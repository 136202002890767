import { PlusOutlined } from '@ant-design/icons';
import type { TrackDistributionConfigUpdateRequest } from '@gql_codegen/price-management-config-types';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { css } from '@styled-system/css';
import { HStack } from '@styled-system/jsx';
import type { CollapseProps } from 'antd';
import { App, Button, Collapse, Popconfirm } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DefaultTrackDistributionFormValues,
  TrackDistributionSchema,
  TrackDistributionSchemaType,
} from './model/trackDistributionSchema';

import {
  useGetDistributionsQuery,
  useUpdateDistributionsMutation,
} from '@gql_codegen/price-management-config-types';
import { formatServerError } from '@src/shared/formating/format-server-error';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { ClusterCollapse } from './entities/ClusterCollapse/ClusterCollapse';
import { CluseterSetupModal } from './entities/ClusterSetupModal/ClusterSetupModal';
import { useTrackDistributionStore } from './store/trackDistributionStore';

export const TrackDistributionConfigs: React.FC = () => {
  const { notification } = App.useApp();
  const [queryData, setQueryData] = useTrackDistributionStore((state) => [
    state.distributionsList,
    state.setQueryData,
  ]);

  const distributionQuery = useConvertGeneratedQueryToSuspense(
    useGetDistributionsQuery,
    undefined,
  );
  useEffect(() => {
    setQueryData(distributionQuery.data.getDistributions);
  }, [distributionQuery.data, setQueryData]);

  const { mutate } = useUpdateDistributionsMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const methods = useForm<TrackDistributionSchemaType>({
    resolver: zodResolver(TrackDistributionSchema),
    defaultValues: DefaultTrackDistributionFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const hasEditedClusters =
    queryData
      .map((c) => {
        return c.priceClusters.filter((cl) => cl.isEdited).length;
      })
      .reduce((acc, i) => acc + i, 0) > 0;

  const handleSaveChanges = () => {
    const mutationInput: TrackDistributionConfigUpdateRequest[] = [];

    queryData.map((c) => {
      c.priceClusters
        .filter((cl) => cl.isEdited)
        .map((cl) => {
          cl.configs.map((cfg) => {
            mutationInput.push({
              priceCluster: cl.priceCluster,
              country: c.country,
              experimentName: cfg.experimentName,
              track: cfg.track,
              distributionRatio: cfg.distributionRatio,
            });
          });
        });
    });
    mutate(
      { input: mutationInput },
      {
        onSuccess: () => {
          notification.success({
            message: 'Configuration saved successfully!',
          });
          // TODO !!!
          // handle success
          // refetch page data useGetDistributionsQuery()
          // set data to zustand to distributionsList
          void (async () => {
            const { data } = await distributionQuery.refetch();
            if (data) setQueryData(data.getDistributions);
          })();
        },
        onError: (error) => {
          // TODO !!!
          //handle error
          notification.error({ message: formatServerError(error) });
        },
      },
    );
  };

  const countryItems: CollapseProps['items'] = useMemo(
    () =>
      queryData.map((country, idx) => {
        const clusterItems = country.priceClusters.map((cluster, i) => {
          return {
            key: i,
            label: cluster.priceCluster,
            style: { background: cluster.isEdited && '#ffff00' },
            ['data-qa-selector']: `clusterCollapseItem-${i}`,
            children: (
              <ClusterCollapse
                showModal={showModal}
                reset={methods.reset}
                country={country.country}
                cluster={cluster}
              />
            ),
          };
        });
        return {
          key: idx,
          label: country.country,
          ['data-qa-selector']: `countryCollapseItem-${idx}`,
          children: <Collapse size="small" items={clusterItems} />,
        };
      }),
    [queryData, showModal, methods.reset],
  );

  return (
    <>
      <HStack justifyContent="space-between" className={css({ m: '15px 0' })}>
        <Popconfirm
          title="Warning"
          description={
            <>
              <div>
                Saving a new experiment track configuration will overwrite the
                existing data.
              </div>
              <div>
                This action cannot be undone. Are you sure you want to proceed?
              </div>
            </>
          }
          onConfirm={showModal}
          okText={
            <span data-qa-selector="createNewTrackDistributionButtonProceed">
              Proceed
            </span>
          }
          cancelText={
            <span data-qa-selector="createNewTrackDistributionButtonCancel">
              Cancel
            </span>
          }
        >
          <Button
            type="default"
            data-qa-selector="createNewTrackDistributionButton"
          >
            <PlusOutlined /> Create new Track Distribution
          </Button>
        </Popconfirm>
        <Button
          type="primary"
          onClick={handleSaveChanges}
          disabled={!hasEditedClusters}
          data-qa-selector="saveChangesButton"
        >
          Save changes
        </Button>
      </HStack>
      <FormProvider {...methods}>
        <CluseterSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        {import.meta.env.DEV && <DevTool control={methods.control} />}
      </FormProvider>
      <Collapse
        data-qa-selector="countryCollapseWrapper"
        items={countryItems}
        defaultActiveKey={countryItems.map((_, idx) => idx)}
      />
    </>
  );
};
