// THIS FILE IS GENERATED, DO NOT EDIT!
/* istanbul ignore next */
/* eslint-disable */
/* @ts-nocheck */
import type { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';
import type { AxiosError } from 'axios';

import { z } from 'zod';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { api } from './api';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  UUID: { input: string; output: string };
};

export type TrackDistributionConfigUpdateRequest = {
  country: Scalars['String']['input'];
  priceCluster: Scalars['String']['input'];
  experimentName: Scalars['String']['input'];
  track: Scalars['String']['input'];
  distributionRatio: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateDistributions: Array<CountryClusterDistributionConfigs>;
};

export type MutationUpdateDistributionsArgs = {
  input: Array<TrackDistributionConfigUpdateRequest>;
};

export type Query = {
  __typename?: 'Query';
  getDictionaries: Dictionaries;
  getDistributions: Array<CountryClusterDistributionConfigs>;
};

export type Dictionaries = {
  __typename?: 'Dictionaries';
  countries: Array<Scalars['String']['output']>;
  priceClusters: Array<Scalars['String']['output']>;
  experimentNames: Array<Scalars['String']['output']>;
  tracks: Array<Scalars['String']['output']>;
};

export type TrackDistributionConfig = {
  __typename?: 'TrackDistributionConfig';
  experimentName: Scalars['String']['output'];
  track: Scalars['String']['output'];
  distributionRatio: Scalars['Int']['output'];
  sourcingType: Scalars['String']['output'];
  countOfAssignedAds: Scalars['Int']['output'];
  examplesOfAssignedAds: Array<Ad>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
};

export type ClusterDistributionConfigs = {
  __typename?: 'ClusterDistributionConfigs';
  priceCluster: Scalars['String']['output'];
  configs: Array<TrackDistributionConfig>;
};

export type CountryClusterDistributionConfigs = {
  __typename?: 'CountryClusterDistributionConfigs';
  country: Scalars['String']['output'];
  priceClusters: Array<ClusterDistributionConfigs>;
};

export type Ad = {
  __typename?: 'Ad';
  label: Scalars['String']['output'];
  adId: Scalars['UUID']['output'];
};

export type GetDistributionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDistributionsQuery = {
  __typename?: 'Query';
  getDistributions: Array<{
    __typename?: 'CountryClusterDistributionConfigs';
    country: string;
    priceClusters: Array<{
      __typename?: 'ClusterDistributionConfigs';
      priceCluster: string;
      configs: Array<{
        __typename?: 'TrackDistributionConfig';
        experimentName: string;
        track: string;
        distributionRatio: number;
        createdAt: string;
        createdBy: string;
        sourcingType: string;
        countOfAssignedAds: number;
        examplesOfAssignedAds: Array<{
          __typename?: 'Ad';
          label: string;
          adId: string;
        }>;
      }>;
    }>;
  }>;
};

export type GetDictionariesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDictionariesQuery = {
  __typename?: 'Query';
  getDictionaries: {
    __typename?: 'Dictionaries';
    countries: Array<string>;
    priceClusters: Array<string>;
    experimentNames: Array<string>;
    tracks: Array<string>;
  };
};

export type UpdateDistributionsMutationVariables = Exact<{
  input:
    | Array<TrackDistributionConfigUpdateRequest>
    | TrackDistributionConfigUpdateRequest;
}>;

export type UpdateDistributionsMutation = {
  __typename?: 'Mutation';
  updateDistributions: Array<{
    __typename?: 'CountryClusterDistributionConfigs';
    country: string;
    priceClusters: Array<{
      __typename?: 'ClusterDistributionConfigs';
      priceCluster: string;
      configs: Array<{
        __typename?: 'TrackDistributionConfig';
        track: string;
        experimentName: string;
        distributionRatio: number;
        sourcingType: string;
        createdAt: string;
        createdBy: string;
      }>;
    }>;
  }>;
};

export const GetDistributionsDocument = `
    query getDistributions {
  getDistributions {
    country
    priceClusters {
      priceCluster
      configs {
        experimentName
        track
        distributionRatio
        createdAt
        createdBy
        sourcingType
        countOfAssignedAds
        examplesOfAssignedAds {
          label
          adId
        }
      }
    }
  }
}
    `;

export const useGetDistributionsQuery = <
  TData = GetDistributionsQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetDistributionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDistributionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetDistributionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetDistributionsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getDistributions']
        : ['getDistributions', variables],
    queryFn: api<GetDistributionsQuery, GetDistributionsQueryVariables>(
      GetDistributionsDocument,
      variables,
    ),
    ...options,
  });
};

useGetDistributionsQuery.getKey = (
  variables?: GetDistributionsQueryVariables,
) =>
  variables === undefined
    ? ['getDistributions']
    : ['getDistributions', variables];

useGetDistributionsQuery.fetcher = (
  variables?: GetDistributionsQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDistributionsQuery, GetDistributionsQueryVariables>(
    GetDistributionsDocument,
    variables,
    options,
  );

export const GetDictionariesDocument = `
    query getDictionaries {
  getDictionaries {
    countries
    priceClusters
    experimentNames
    tracks
  }
}
    `;

export const useGetDictionariesQuery = <
  TData = GetDictionariesQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetDictionariesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDictionariesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetDictionariesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetDictionariesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getDictionaries']
        : ['getDictionaries', variables],
    queryFn: api<GetDictionariesQuery, GetDictionariesQueryVariables>(
      GetDictionariesDocument,
      variables,
    ),
    ...options,
  });
};

useGetDictionariesQuery.getKey = (variables?: GetDictionariesQueryVariables) =>
  variables === undefined
    ? ['getDictionaries']
    : ['getDictionaries', variables];

useGetDictionariesQuery.fetcher = (
  variables?: GetDictionariesQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDictionariesQuery, GetDictionariesQueryVariables>(
    GetDictionariesDocument,
    variables,
    options,
  );

export const UpdateDistributionsDocument = `
    mutation updateDistributions($input: [TrackDistributionConfigUpdateRequest!]!) {
  updateDistributions(input: $input) {
    country
    priceClusters {
      priceCluster
      configs {
        track
        experimentName
        distributionRatio
        sourcingType
        createdAt
        createdBy
      }
    }
  }
}
    `;

export const useUpdateDistributionsMutation = <
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDistributionsMutation,
    TError,
    UpdateDistributionsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateDistributionsMutation,
    TError,
    UpdateDistributionsMutationVariables,
    TContext
  >({
    mutationKey: ['updateDistributions'],
    mutationFn: (variables?: UpdateDistributionsMutationVariables) =>
      api<UpdateDistributionsMutation, UpdateDistributionsMutationVariables>(
        UpdateDistributionsDocument,
        variables,
      )(),
    ...options,
  });
};

useUpdateDistributionsMutation.fetcher = (
  variables: UpdateDistributionsMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<UpdateDistributionsMutation, UpdateDistributionsMutationVariables>(
    UpdateDistributionsDocument,
    variables,
    options,
  );

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z
  .any()
  .refine((v) => isDefinedNonNullAny(v));

export function TrackDistributionConfigUpdateRequestSchema(): z.ZodObject<
  Properties<TrackDistributionConfigUpdateRequest>
> {
  return z.object({
    country: z.string(),
    priceCluster: z.string(),
    experimentName: z.string(),
    track: z.string(),
    distributionRatio: z.number().int(),
  });
}
