import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { TrackDistributionSchemaType } from '../model/trackDistributionSchema';
import type {
  CountryClusterDistributionConfigs,
  Ad,
} from '@gql_codegen/price-management-config-types';
import { notification } from 'antd';
import isMatch from 'lodash.ismatch';

export type TrackDistributionConfigWithOptionalData = {
  experimentName: string;
  track: string;
  distributionRatio: number;
  createdBy?: string;
  createdAt?: string;
  sourcingType?: string;
  countOfAssignedAds?: number;
  examplesOfAssignedAds?: Ad[];
};
export type ClusterType = {
  priceCluster: string;
  configs: TrackDistributionConfigWithOptionalData[];
  isEdited?: boolean;
};
interface TrackDistributionStoreType {
  distributionsList: {
    country: string;
    priceClusters: ClusterType[];
  }[];
  setNewClusterData: (data: TrackDistributionSchemaType) => void;
  setQueryData: (data: CountryClusterDistributionConfigs[]) => void;
}

export const useTrackDistributionStore = create<TrackDistributionStoreType>()(
  devtools(
    immer((set) => ({
      distributionsList: [],
      setQueryData: (data) =>
        set((state) => {
          state.distributionsList = data;
        }),
      setNewClusterData: (data) =>
        set((state) => {
          const country = state.distributionsList.find(
            (c) => c.country === data.country,
          );
          const newClusterData = {
            isEdited: true,
            priceCluster: data.priceCluster,
            configs: data.configs,
          };
          if (!country) {
            // for now we can edit only existing countries, not create a new one from UI
            return notification.error({
              message: 'No country found',
            });
          }
          // search for the last cluster by its name, in case the cluster was already edited and added below the original, index of edited cluster will be returned
          const clusterIndex = country.priceClusters.findLastIndex(
            (cl) => cl.priceCluster === data.priceCluster,
          );
          if (clusterIndex >= 0) {
            // editing existing cluster (either edited or original)

            if (country.priceClusters[clusterIndex]?.isEdited) {
              // here we are editing already edited and highlighted cluster
              const filteredInternal =
                country.priceClusters[clusterIndex - 1]?.configs.filter(
                  (c) => c.sourcingType === 'internal',
                ) ?? []; // we want to find original cluster and filter only internal
              const isConfigsLengthEqualToOriginal =
                data.configs.length === filteredInternal.length;
              const isConfigsEqualToOriginal = data.configs
                .map((cfg, i) => isMatch(filteredInternal[i] ?? {}, cfg))
                .every((i) => i);

              if (isConfigsLengthEqualToOriginal && isConfigsEqualToOriginal) {
                // if we are editing already edited cluster we need to compare it to original cluster and remove record if they are equal
                country.priceClusters.splice(clusterIndex, 1);
                return notification.error({
                  message: 'Skipped, Configuration already exists!',
                });
              }
              //now compare new config data to edited cluster
              const isConfigsLengthEqualToEdited =
                data.configs.length ===
                country.priceClusters[clusterIndex]?.configs.length;
              const isConfigsEqualToEdited = data.configs
                .map((cfg, i) =>
                  isMatch(
                    country.priceClusters[clusterIndex]?.configs[i] ?? {},
                    cfg,
                  ),
                )
                .every((i) => i);
              if (isConfigsLengthEqualToEdited && isConfigsEqualToEdited) {
                // if we  create a new cluster distribution but it is equal to an edited one we just skip
                return notification.error({
                  message: 'Skipped, Configuration already exists!',
                });
              }
              // if we reached this point then we just mutate edited cluster with new data
              country.priceClusters[clusterIndex] = newClusterData;
            } else {
              const filteredInternal =
                country.priceClusters[clusterIndex]?.configs.filter(
                  (c) => c.sourcingType === 'internal',
                ) ?? [];
              const isConfigsLengthEqualToEdited =
                data.configs.length === filteredInternal.length;
              const isConfigsEqualToEdited = data.configs
                .map((cfg, i) => isMatch(filteredInternal[i] ?? {}, cfg))
                .every((i) => i);
              if (isConfigsLengthEqualToEdited && isConfigsEqualToEdited) {
                // if we edit original cluster and data is equal then we skip it
                return notification.error({
                  message: 'Skipped, Configuration already exists!',
                });
              }
              // otherwise we place a new (edited) cluster right below original one
              country.priceClusters.splice(clusterIndex + 1, 0, newClusterData);
            }
          } else {
            // creating new cluster, just push a new item to the end
            country.priceClusters.push(newClusterData);
          }
        }),
    })),
    {
      name: 'TrackDistributionStore',
      anonymousActionType: 'TrackDistributionStoreAction',
    },
  ),
);
