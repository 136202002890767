import { Space, Typography } from 'antd';
import { TrackDistributionConfigs } from './TrackDistributionConfig';
export const OverviewPage = () => {
  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Typography.Title data-qa-selector="pageMainHeader">
        Pricing Configurations
      </Typography.Title>
      <TrackDistributionConfigs />
    </Space>
  );
};
